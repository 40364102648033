<template>
  <div class="wrrapper">
    <cookies-popup />
    <navigation :isWhite="isWhiteNav"></navigation>
    <iframe
      class="frame-bg frame-bg-visible"
      scrolling="no"
      src="/backgrounds/contact.html"
    ></iframe>
    <div class="content-page-wrapper">
      <div class="content">
        <div class="general-info">
          <h1 class="page-title-desktop">{{ $t("contactUs.title") }}</h1>
          <div class="addresses-wrapper">
            <h2>{{ $t("contactUs.officesTitle") }}</h2>
            <div class="offices-addresses">
              <div class="office-address">
                <h3>
                  {{ $t("contactUs.addressNewYork.title") }} -
                  <a
                    class="a-common-gray"
                    href="https://goo.gl/maps/KKtRdNNgnCwsD6Qt8"
                    target="_blank"
                    >MAP</a
                  >
                </h3>
                <address>
                  {{ $t("contactUs.addressNewYork.line1") }}<br />
                  {{ $t("contactUs.addressNewYork.line2") }}<br />
                  {{ $t("contactUs.addressNewYork.line3") }}
                </address>
                <div class="phone">
                  <a class="a-common-gray" href="mailto:newyork@dnd.promo"
                    >newyork@dnd.promo</a
                  >
                </div>
              </div>
              <div class="office-address">
                <h3>
                  {{ $t("contactUs.addressKyiv.title") }} -
                  <a
                    class="a-common-gray"
                    href="https://goo.gl/maps/eVwvVbn6PpMXsMBS9"
                    target="_blank"
                    >MAP</a
                  >
                </h3>
                <address>
                  {{ $t("contactUs.addressKyiv.line1") }}<br />
                  {{ $t("contactUs.addressKyiv.line2") }}<br />
                  {{ $t("contactUs.addressKyiv.line3") }}
                </address>
                <div class="phone">
                  <a class="a-common-gray" href="mailto:kyiv@dnd.promo"
                    >kyiv@dnd.promo</a
                  >
                </div>
              </div>
              <div class="office-address">
                <h3>
                  {{ $t("contactUs.addressSharjah.title") }} -
                  <a
                    class="a-common-gray"
                    href="https://g.page/shamssmc?share"
                    target="_blank"
                    >MAP</a
                  >
                </h3>
                <address>
                  {{ $t("contactUs.addressSharjah.line1") }}<br />
                  {{ $t("contactUs.addressSharjah.line2") }}<br />
                  {{ $t("contactUs.addressSharjah.line3") }}
                </address>
                <div class="phone">
                  <a class="a-common-gray" href="mailto:sharjah@dnd.promo"
                    >sharjah@dnd.promo</a
                  >
                </div>
              </div>
              <div class="office-address">
                <h3>
                  {{ $t("contactUs.addressTokyo.title") }} -
                  <a
                    class="a-common-gray"
                    href="https://goo.gl/maps/WVbRPi4HhwZhmBTE6"
                    target="_blank"
                    >MAP</a
                  >
                </h3>
                <address>
                  {{ $t("contactUs.addressTokyo.line1") }}<br />
                  {{ $t("contactUs.addressTokyo.line2") }}<br />
                  {{ $t("contactUs.addressTokyo.line3") }}
                </address>
                <div class="phone">
                  <a class="a-common-gray" href="mailto:tokyo@dnd.promo"
                    >tokyo@dnd.promo</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="emails">
          <h1 class="page-title-mobile">{{ $t("contactUs.title") }}</h1>
          <div class="group">
            <input
              :disabled="isFormDisabled"
              v-model="name"
              :class="{ error: isNameTouched && !isNameExists }"
              type="text"
              @blur="isNameTouched = true"
              :placeholder="$t('contactUs.form.firstName')"
            />
            <div class="error-message">
              <span v-if="isNameTouched && !isNameExists">{{
                $t("contactUs.form.required")
              }}</span>
            </div>
          </div>
          <div class="group">
            <input
              :disabled="isFormDisabled"
              v-model="lastName"
              :class="{ error: isLastNameTouched && !isLastNameExists }"
              type="text"
              @blur="isLastNameTouched = true"
              :placeholder="$t('contactUs.form.lastName')"
            />
            <div class="error-message">
              <span v-if="isLastNameTouched && !isLastNameExists">{{
                $t("contactUs.form.required")
              }}</span>
            </div>
          </div>
          <div class="group">
            <input
              :disabled="isFormDisabled"
              v-model="email"
              :class="{
                error: isEmailTouched && (!isEmailExists || !isEmailValid)
              }"
              type="text"
              @blur="isEmailTouched = true"
              :placeholder="$t('contactUs.form.emailPlaceholder')"
            />
            <div class="error-message">
              <span v-if="isEmailTouched && !isEmailExists">{{
                $t("contactUs.form.required")
              }}</span>
              <span v-if="isEmailTouched && isEmailExists && !isEmailValid">{{
                $t("contactUs.form.emailInvalid")
              }}</span>
            </div>
          </div>
          <div class="group">
            <input
              :disabled="isFormDisabled"
              v-model="company"
              type="text"
              @blur="isCompanyTouched = true"
              :placeholder="$t('contactUs.form.company')"
            />
          </div>
          <div class="group">
            <div class="desctop-select" :class="{ droped: isSelectShown }">
              <span v-on:click="showCloseSelect" v-if="inquaryType == 0">{{
                $t("contactUs.form.select.inquaryType")
              }}</span>
              <span v-on:click="showCloseSelect" v-if="inquaryType == 1">{{
                $t("contactUs.form.select.artist")
              }}</span>
              <span v-on:click="showCloseSelect" v-if="inquaryType == 2">{{
                $t("contactUs.form.select.promote")
              }}</span>
              <span v-on:click="showCloseSelect" v-if="inquaryType == 3">{{
                $t("contactUs.form.select.partnership")
              }}</span>
              <span v-on:click="showCloseSelect" v-if="inquaryType == 4">{{
                $t("contactUs.form.select.other")
              }}</span>
              <ul>
                <li v-on:click="inquaryType = 1">
                  {{ $t("contactUs.form.select.artist") }}
                </li>
                <li v-on:click="inquaryType = 2">
                  {{ $t("contactUs.form.select.promote") }}
                </li>
                <li v-on:click="inquaryType = 3">
                  {{ $t("contactUs.form.select.partnership") }}
                </li>
                <li v-on:click="inquaryType = 4">
                  {{ $t("contactUs.form.select.other") }}
                </li>
              </ul>
            </div>
            <div class="mobile-select">
              <select v-model="inquaryType">
                <option selected disabled value="0">{{
                  $t("contactUs.form.select.inquaryType")
                }}</option>
                <option value="1">{{
                  $t("contactUs.form.select.artist")
                }}</option>
                <option value="2">{{
                  $t("contactUs.form.select.promote")
                }}</option>
                <option value="3">{{
                  $t("contactUs.form.select.partnership")
                }}</option>
                <option value="4">{{
                  $t("contactUs.form.select.other")
                }}</option>
              </select>
            </div>
          </div>
          <div class="group">
            <textarea
              :disabled="isFormDisabled"
              v-model="message"
              @blur="isMessageTouched = true"
              :class="{ error: isMessageTouched && !isMessageExists }"
              :placeholder="$t('contactUs.form.messagePlaceholder')"
            ></textarea>
            <div class="error-message">
              <span v-if="isMessageTouched && !isMessageExists">{{
                $t("contactUs.form.required")
              }}</span>
              <span v-if="isMessageSent" class="success-note">
                {{ $t("contactUs.form.successMessage") }}
              </span>
            </div>
          </div>
          <div class="group">
            <button v-on:click="onSubmit" :disabled="isFormDisabled">
              <span class="button-text">
                {{ $t("contactUs.form.btnString") }}
              </span>
              <span class="button-bg">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <stub />
    <footerSection />
  </div>
</template>

<script>
import navigation from "./../components/navigation";
import footerSection from "./../sections/footer-small";
import stub from "./../sections/stub";
import cookiesPopup from './../components/cookies-popup';
const emailCheckRegex = /\S+@\S+\.\S+/;

export default {
  data() {
    return {
      isSelectShown: false,
      inquaryType: 0,
      email: null,
      name: null,
      lastName: null,
      company: null,
      message: null,

      isSentError: null,
      isFormDisabled: false,


      isNameTouched: false,
      isLastNameTouched:false,
      isCompanyTouched: false,
      isEmailTouched: false,
      isMessageTouched: false,
      isMessageSent: false,

      isWhiteNav: false,

      mobileWidth: 793,
      tabletWidth: 992
    };
  },
  metaInfo: {
    title: "D&D Promotion - Let‘s Talk."
  },
  components: {
    navigation,
    footerSection,
    stub,
    cookiesPopup
  },
  methods: {
    hasValue(val) {
      return val !== null && val !== undefined && val !== "";
    },
    showCloseSelect(event) {
      if (this.isFormDisabled) {
        return;
      }

      event.stopPropagation();

      this.isSelectShown = !this.isSelectShown;
    },
    onSubmit() {
      this.isEmailTouched = true;
      this.isNameTouched = true;
      this.isMessageTouched = true;
      this.isLastNameTouched = true;
      this.isCompanyTouched = true;

      if (!this.isFormValid() || this.isFormDisabled) {
        return;
      }

      this.isFormDisabled = true;

      const successHand = () => {
        this.isMessageSent = true;

        this.isEmailTouched = false;
        this.isNameTouched = false;
        this.isMessageTouched = false;
        this.isLastNameTouched = false;
        this.isCompanyTouched = false;

        this.inquaryType = 0;
        this.email = null;
        this.name = null;
        this.lastName = null;
        this.company = null;
        this.message = null;

        this.isFormDisabled = true;
      };
      this.$http({
        url: "contact",
        method: "POST",
        data: {
          name: this.name + " " + this.lastName,
          email: this.email,
          text: this.message,
          company: this.company,
          type: this.inquaryType,
          locale: this.$i18n.locale,
          device: this.getDeviseName()
        }
      })
        .then(successHand)
        .catch(() => (this.isSentError = true));
    },
    isFormValid() {
      return (
        this.isEmailExists &&
        this.isEmailValid &&
        this.isLastNameExists &&
        this.isNameExists &&
        this.isMessageExists
      );
    },
    isMobile() {
      return this.getWindowWidth() <= this.mobileWidth;
    },
    isTablet() {
      return (
        this.getWindowWidth() >= this.mobileWidth &&
        this.getWindowWidth() <= this.tabletWidth
      );
    },
    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    },
    getDeviseName() {
      if (this.isTablet()) {
        return "Tablet";
      }

      if (this.isMobile()) {
        return "Mobile";
      }

      return "Desktop";
    }
  },
  mounted() {
    document.addEventListener("click", () => {
      this.isSelectShown = false;
    });
    this.isWhiteNav = this.isMobile() || this.isTablet();
  },
  computed: {
    isEmailExists() {
      return this.hasValue(this.email);
    },
    isNameExists() {
      return this.hasValue(this.name);
    },
    isLastNameExists() {
      return this.hasValue(this.lastName);
    },
    isMessageExists() {
      return this.hasValue(this.message);
    },
    isEmailValid() {
      return emailCheckRegex.test(this.email);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/app";

.page-title-desktop {
  display: block;
}

.page-title-mobile {
  display: none;
}

.content {
  position: relative;
  background: #ffffff;
  padding: 15vw 10vw 5vw;
  display: flex;
  justify-content: space-between;
}

.general-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-family: $family-light;
    letter-spacing: 0;
    font-size: 5.2vw;
    margin: 0 0 2.5vw;
    width: 100%;
  }
}

.addresses-wrapper {
  width: 90%;
  padding-top: 13vw;
  h2 {
    font-family: $family-light;
    font-size: 3rem;
    width: 400px;
    margin: 0 0 1.5rem;
    line-height: calc(1em + 8px);
  }
}

.offices-addresses {
  display: flex;
  flex-wrap: wrap;

  h3 {
    margin-bottom: 0.25rem;
    font-size: 1rem;
    color: #3d5154;
  }

  address {
    line-height: 1.5em;
    font-size: 0.8125rem;
  }

  .phone {
    line-height: 1.5em;
    font-size: 0.8125rem;
  }
}

.office-address {
  width: 220px;
  padding: 0 50px 40px 0;

  h3 {
    font-family: $family-medium;
    padding-bottom: 0.5em;
  }

  address {
    white-space: nowrap;
    font-family: $family-light;
    line-height: 1.5em;
    @include font-size(15px);
  }
}

.emails {
  width: 500px;
}

.error-message {
  span {
    color: #f36;
    font-family: $family-light;
  }
  .success-note {
    color: green;
    font-family: $family-light;
  }
}

$duration: 0.35s;
$charCoutn: 100;
$colorBlack: rgb(25, 26, 26);
$colorLight: rgb(255, 255, 255);
$colorMiddle: rgb(255, 217, 0);
$colorActive: rgb(73, 35, 216);

.group {
  width: 100%;
  padding: 0 0 20px 0;

  input,
  textarea,
  select,
  button {
    display: block;
    width: 100%;
    outline: none;
    padding: 5px;
    border: 1px solid #d0d5d8;
    border-radius: 2px;
    resize: none;
    padding: 18px 15px;
    @include font-size(18px);
    color: #8a959e;
    background: transparent;
    font-family: $family-light;
    &::placeholder {
      color: #8a959e;
    }

    &.error {
      border-color: #f36;
    }
  }

  textarea {
    height: 224px;
  }

  button {
    position: relative;
    border-radius: 5px;
    height: 63px;
    border: 0px solid transparent;
    border-radius: 2px;
    overflow: hidden;

    .button-text {
      line-height: 66px;
      position: absolute;
      text-transform: uppercase;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-family: $family-regular;
      @include font-size(20px);
      color: #fff;
      background-color: transparent;
      z-index: 1;
      overflow: hidden;
    }

    .button-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #1d1f1f;
      z-index: 0;
      display: flex;

      span {
        display: block;
        width: 100%;
        height: 100%;
        @include transition(color-backward $duration);
        @include animation(color-backward $duration);
        @include animation-fill-mode(both);

        @for $i from 1 through $charCoutn {
          &:nth-child(#{$charCoutn}n + #{$i}) {
            @include animation-delay(
              ($duration / $charCoutn) * ($charCoutn - $i)
            );
          }
        }
      }
    }

    &:hover {
      .button-bg {
        span {
          @include transition(color-forward $duration);
          @include animation(color-forward $duration);
          @include animation-fill-mode(both);

          @for $i from 1 through $charCoutn {
            &:nth-child(#{$charCoutn}n + #{$i}) {
              @include animation-delay($duration / $charCoutn * $i);
            }
          }
        }
      }
    }

    &:disabled,
    &:disabled:hover {
      background-color: #ccc;
      .button-bg {
        background-color: #ccc;
        span {
          @include animation(none);
          background-color: #ccc;
        }
      }
    }
  }

  @include keyframes(color-forward) {
    0% {
      background: $colorBlack;
    }
    10% {
      background: rgb(246, 189, 37);
    }
    20% {
      background: rgb(239, 161, 55);
    }
    30% {
      background: rgb(231, 133, 73);
    }
    40% {
      background: rgb(222, 100, 94);
    }
    50% {
      background: rgb(214, 68, 114);
    }
    60% {
      background: rgb(180, 59, 139);
    }
    70% {
      background: rgb(144, 51, 165);
    }
    80% {
      background: rgb(73, 35, 216);
    }
    100% {
      background: $colorActive;
    }
  }

  @include keyframes(color-backward) {
    0% {
      background: $colorActive;
    }
    100% {
      background: $colorBlack;
    }
  }

  .desctop-select {
    position: relative;

    span {
      font-family: $family-light;
      position: relative;
      display: block;
      border: 1px solid #d0d5d8;
      border-radius: 2px;
      padding: 18px 15px;
      cursor: pointer;
      color: #8a959e;
      @include font-size(18px);

      &::after {
        content: "";
        position: absolute;
        margin-top: -0.6em;
        border: solid transparent;
        border-bottom-color: #a4abb1;
        border-width: 0.3em;
        right: 0.8em;
        top: 50%;
        z-index: 1;
      }

      &::before {
        content: "";
        position: absolute;
        margin-top: 0.2em;
        border: solid transparent;
        border-width: 0.3em;
        border-top-color: #a4abb1;
        right: 0.8em;
        top: 50%;
        z-index: 1;
      }
    }

    ul {
      display: none;
      position: absolute;
      background: #fff;
      width: 100%;
      border: 1px solid #d0d5d8;
      border-top: 0px;

      li {
        cursor: pointer;
        color: #8a959e;
        @include font-size(18px);
        padding: 18px 15px;
        font-family: $family-light;

        &:hover {
          background: #d0d5d8;
        }
      }
    }

    &.droped {
      ul {
        display: block;
      }
    }
  }

  .mobile-select {
    position: relative;
    display: none;
    cursor: pointer;
    color: #8a959e;
    @include font-size(18px);

    &::after {
      content: "";
      position: absolute;
      margin-top: -0.6em;
      border: solid transparent;
      border-bottom-color: #a4abb1;
      border-width: 0.3em;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      margin-top: 0.2em;
      border: solid transparent;
      border-width: 0.3em;
      border-top-color: #a4abb1;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }
  }
}

.shader-container {
  overflow: hidden;
  height: 70vh;
  position: relative;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 992px) {

  .mobile-select {
    display: block;
  }

  .desctop-select {
    display: none;
  }

  .page-title-desktop {
    display: none;
  }

  .page-title-mobile {
    display: block;
    padding: 0 0 5vh 0;
  }

  .addresses-wrapper {
    padding-top: 0;
  }

  .group .mobile-select {
    display: block;
  }

  .content {
    flex-direction: column-reverse;
  }

  .general-info h1 {
    font-size: 12vw;
    margin: 10vw 0 2.5vw;
  }

  .general-info address {
    margin-top: auto;
    margin-bottom: 1vw;
  }

  .emails {
    margin: 10vw 0;

    h5 {
      font-size: calc(15px + 2 * ((100vw - 375px) / 649));
    }

    a {
      font-size: 7.5vw;
    }
  }

  .shader-container {
    height: 650px;
  }
}

@media (max-width: 576px) {


  .addresses-wrapper {
    width: 100%;

    h2 {
      padding-top: 5vw;
      width: 100%;
      font-size: 2.125rem;
    }
  }

  .offices-addresses {
    flex-direction: column;
    h3 {
      font-size: 1rem;
    }
  }

  .office-address {
    padding: 20px;
    width: 100%;
  }

  .mobile-select {
    display: block;
  }

  .content-page-wrapper {
    min-height: auto;
    height: auto;
  }

  .content {
    padding: 25vw 10vw 10vw;
  }

  .emails {
    width: 100%;
  }
}
</style>
